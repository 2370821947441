<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
      style="font-size:35px; background:linear-gradient(180deg, #339ACF 0.5%, #263AA2 100%);"
    >
      <v-list
        dense
        nav
        style="  color: white;"
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :href="item.href"
          :to="item.to"
          link
          class="navmenu"
          style="color: white;"
        >
          <v-list-item-icon>
            <v-icon style="color: white;">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="footer">
        <div class="footertext">Created by MadhavSaladi(BabluS)</div>
        <div class="footertext">{{ new Date().getFullYear() }} - <strong>Team KharaMe</strong></div>
      </div>
    </v-navigation-drawer>

    <v-app-bar app style="background:none;box-shadow:none;color:white">

    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" @click="drawer = !drawer" class="bi bi-list" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
    </svg>

      <v-toolbar-title style="font-size:35px">KharaMe</v-toolbar-title>
      <v-avatar v-show="loggedin" @click="gotoAccount()" style="position:absolute;right:2%;top:8%;cursor: pointer;"><v-icon dark>bi bi-person-circle</v-icon></v-avatar>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  
  export default {
    name: 'App',
    components: {

    },
    data: () => ({
      drawer: null,
      loggedin: false,
      items: [
        { title: 'Home', icon: 'bi bi-house-fill', href: '/' },
        { title: 'Realme 5 Pro', icon: 'bi bi-phone-fill', to : '/rmx1971' },
        { title: 'Realme 5 Series', icon: 'bi bi-phone-fill', to : '/r5x' },
        { title: 'Blog', icon: 'bi bi-file-text-fill', to: '/blog' },
        { title: 'Maintainers', icon: 'bi bi-file-code-fill', to : '/maintainers' },
        { title: 'About', icon: 'mdi-help-box', to: '/about' },
      ],
    }),
    created () {
      this.checkloggedin()
    },

    methods: {
      checkloggedin() {
        this.current_time = Math.round(+new Date()/1000);
        if (sessionStorage.getItem("token") && sessionStorage.getItem("expire_time") > this.current_time) {
          this.loggedin= true;
        } else {
          sessionStorage.clear();
        }
      },
      gotoAccount() {
        this.$router.push('/account');
      }
    },
  }
</script>
<style>
#inspire {
  background : linear-gradient(180deg, #339ACF 0.5%, #263AA2 100%);
  font-family: 'Open Sans', sans-serif;
}
.bi-list {
  cursor: pointer;
  margin-right: 1%;
}
.navmenu {
  margin-top: 5%;
}
.footer {
  margin-top: 2%;
  position: absolute;
  font-size: 15px;
  bottom: 0;
  width: 100%;
  text-align: center;
  border-top: solid;
  color: white;
  border-top-width: 1px;
}
.footertext {
  margin: 1%;
}
</style>