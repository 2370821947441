import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/rmx1971',
    name: 'Realme5Pro',
    component: () => import('../views/Realme5Pro.vue')
  },
  {
    path: '/r5x',
    name: 'Realme5Series',
    component: () => import('../views/Realme5Series.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/builds',
    name: 'Builds',
    component: () => import('../views/Builds.vue')
  },
  {
    path: '/maintainers',
    name: 'Maintainers',
    component: () => import('../views/Maintainers.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  },
  {
    path: '/:name',
    name: 'MaintainersData',
    component: () => import('../views/MaintainerData.vue')
  },

]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
